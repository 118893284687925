import Head from 'next/head'
import { NextPageWithLayout } from '../_app'
import LoginWrapper from '../../Components/Auth/Login/LoginWrapper'
import AuthLayout from '../../Components/Layouts/AuthLayout/AuthLayout'

const AuthPage: NextPageWithLayout = () => {
  return (
    <>
      <Head>
        <title>Jobin | Login</title>
      </Head>
      <LoginWrapper />
    </>
  )
}

AuthPage.getLayout = page => <AuthLayout>{page}</AuthLayout>

export default AuthPage
