import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import Input from '../../Common/Input/Input'
import PasswordInput from '../../Common/PasswordInput/PasswordInput'
import Link from 'next/link'
import LoginWithSocial from '../LoginWithSocial'
import { signIn } from 'next-auth/react'
import toast from 'react-hot-toast'
import { useRouter } from 'next/router'
import { FormEvent, useState } from 'react'
import Logo from '../../Common/Logo/Logo'

const LoginWrapper: React.FC = () => {
  const router = useRouter()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const credentialsLoginHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const res = await signIn('credentials', {
        redirect: false,
        email,
        password,
      })

      if (res?.status === 200) router.push('/r')
      else toast.error('Invalid credentials')
    } catch (error) {}
  }

  return (
    <Stack
      width={1}
      component={'form'}
      className="default-form"
      onSubmit={credentialsLoginHandler}
    >
      <Box mb={5}>
        <Link href={'https://jobin.ai'}>
          <Logo />
        </Link>
      </Box>
      <Typography variant="h5_xl" mb={3.75}>
        Login to Jobin.ai
      </Typography>
      <Stack spacing={3.75}>
        <Input
          type="email"
          id="email"
          label="Email"
          placeholder="johndoe@gmail.com"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <PasswordInput
          label="Password"
          placeholder="Password"
          id="password-modal"
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
        />
      </Stack>
      <Stack
        mt={2.5}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="body_s_regular">Remember Me</Typography>
            }
            labelPlacement="end"
          />
        </Box>
        <Link href="/auth/forgot-password">
          <Typography variant="body_s_medium">Forgot your password?</Typography>
        </Link>
      </Stack>
      <Stack spacing={2.5} mt={3.75}>
        <Button type="submit">Log In</Button>
        <Stack direction={'row'} spacing={1} justifyContent={'center'}>
          <Typography variant="body_s_regular" color={'darkGray.main'}>
            Don&apos;t have an account?
          </Typography>
          <Link href={'/auth/register'}>
            <Typography variant="body_s_medium" color={'mediumGreen.main'}>
              Signup
            </Typography>
          </Link>
        </Stack>
        <Divider>
          <Typography variant="body_s_regular" color={'mediumGray.main'}>
            or
          </Typography>
        </Divider>
        <LoginWithSocial />
      </Stack>
    </Stack>
  )
}

export default LoginWrapper
